var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row pt-3"
  }, [_c('div', {
    staticClass: "col-md-5"
  }, [_c('div', {
    staticClass: "d-flex justify-content-center"
  }, [_c('div', {
    staticClass: "bufor"
  }, [_c('i', {
    staticClass: "fas",
    class: _vm.energy
  }), _c('div', {
    staticClass: "b-temp d-flex flex-column"
  }, [_c('div', {
    staticClass: "temp"
  }, [_c('StatBtn', {
    attrs: {
      "variable": "t4",
      "driver": "piec",
      "type": "list"
    }
  }, [_vm._v(" " + _vm._s(_vm.piec.t4) + " ")])], 1), _c('div', {
    staticClass: "temp"
  }, [_c('StatBtn', {
    attrs: {
      "variable": "t5",
      "driver": "piec",
      "type": "list"
    }
  }, [_vm._v(" " + _vm._s(_vm.piec.t5) + " ")])], 1), _c('div', {
    staticClass: "temp"
  }, [_c('StatBtn', {
    attrs: {
      "variable": "t6",
      "driver": "piec",
      "type": "list"
    }
  }, [_vm._v(" " + _vm._s(_vm.piec.t6) + " ")])], 1), _c('div', {
    staticClass: "temp"
  }, [_c('StatBtn', {
    attrs: {
      "variable": "t7",
      "driver": "piec",
      "type": "list"
    }
  }, [_vm._v(" " + _vm._s(_vm.piec.t7) + " ")])], 1)])])])]), _c('div', {
    staticClass: "col-md-7"
  }, [_c('h5', {
    staticClass: "mt-3 mt-md-4 pt-3 text-center text-md-left"
  }, [_vm._v(" Sposób ogrzewania ")]), _c('div', {
    staticClass: "d-flex justify-content-center justify-content-md-start flex-wrap"
  }, [_c('FunctionBtn', {
    staticClass: "my-3",
    attrs: {
      "driver": "piec",
      "func": "dmode",
      "autoload": true,
      "size": "sm"
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function () {
        return undefined;
      },
      proxy: true
    }])
  })], 1), _c('h5', {
    staticClass: "mt-3 text-center text-md-left"
  }, [_vm._v("Statystyki")]), _c('div', {
    staticClass: "my-2 d-flex justify-content-center justify-content-md-start flex-wrap"
  }, [_c('StatBtn', {
    staticClass: "badge badge-primary badge-xl mr-2 mb-2",
    attrs: {
      "variable": "grz_b1",
      "data-param": "count",
      "driver": "piec"
    }
  }, [_vm._v(" Stan grzałki 1: " + _vm._s(_vm.piec.grz_b1) + " ")]), _c('StatBtn', {
    staticClass: "badge badge-primary badge-xl mr-2 mb-2",
    attrs: {
      "variable": "grz_b2",
      "data-param": "count",
      "driver": "piec"
    }
  }, [_vm._v(" Stan grzałki 2: "), _c('strong', [_vm._v(_vm._s(_vm.piec.grz_b2))])]), _c('StatBtn', {
    staticClass: "badge badge-primary badge-xl mr-2 mb-2",
    attrs: {
      "variable": "grz_b3",
      "data-param": "count",
      "driver": "piec"
    }
  }, [_vm._v(" Stan grzałki 3: "), _c('strong', [_vm._v(_vm._s(_vm.piec.grz_b3))])]), _c('StatBtn', {
    staticClass: "badge badge-primary badge-xl mr-2 mb-2",
    attrs: {
      "variable": "pomp_ciepla",
      "data-param": "count",
      "driver": "piec"
    }
  }, [_vm._v(" Pompa ciepła: "), _c('strong', [_vm._v(_vm._s(_vm.piec.pomp_ciepla))])])], 1), _c('hr'), _c('div', {
    staticClass: "mt-3 text-center text-md-left"
  }, [_vm._v(" Użycie podłogówki: "), _c('StatBtn', {
    staticClass: "badge badge-light badge-xl",
    attrs: {
      "variable": "floorUsage",
      "driver": "piec",
      "type": "list"
    }
  }, [_c('strong', [_vm._v(" " + _vm._s(_vm.piec.floorUsage) + "% ")])])], 1), _c('div', {
    staticClass: "mt-3 text-center text-md-left"
  }, [_vm._v(" Temp powrotu pompy ciepła: "), _c('StatBtn', {
    staticClass: "badge badge-light badge-xl",
    attrs: {
      "variable": "t2",
      "driver": "piec",
      "type": "list"
    }
  }, [_c('strong', [_vm._v(" " + _vm._s(_vm.piec.t2))])])], 1), _c('hr'), _c('div', {
    staticClass: "mt-3 text-center text-md-left d-flex justify-content-between align-items-center"
  }, [_c('div', [_vm._v("Inv PC; Garaż:")]), _c('FunctionBtn', {
    attrs: {
      "driver": "piec",
      "func": "inverter",
      "autoload": true,
      "align": "right",
      "size": "sm"
    }
  })], 1), _c('div', {
    staticClass: "mt-3 text-center text-md-left d-flex justify-content-between align-items-center"
  }, [_c('div', [_vm._v("Max temp PC:")]), _c('FunctionBtn', {
    attrs: {
      "driver": "piec",
      "func": "setmaxpctemp",
      "autoload": true,
      "align": "right",
      "size": "sm"
    }
  })], 1), _c('div', {
    staticClass: "mt-3 text-center text-md-left d-flex justify-content-between align-items-center"
  }, [_c('div', [_vm._v("Max temp PC (2 taryfa):")]), _c('FunctionBtn', {
    attrs: {
      "driver": "piec",
      "func": "setmaxpctnoc",
      "autoload": true,
      "align": "right",
      "size": "sm"
    }
  })], 1)])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }