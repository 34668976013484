<template>
  <div class="container">
    <div class="row pt-3">
      <div class="col-md-5">
        <div class="d-flex justify-content-center">
          <div class="bufor">
            <i class="fas" :class="energy"></i>
            <div class="b-temp d-flex flex-column">
              <div class="temp">
                <StatBtn variable="t4" driver="piec" type="list">
                  {{ piec.t4 }}
                </StatBtn>
              </div>
              <div class="temp">
                <StatBtn variable="t5" driver="piec" type="list">
                  {{ piec.t5 }}
                </StatBtn>
              </div>
              <div class="temp">
                <StatBtn variable="t6" driver="piec" type="list">
                  {{ piec.t6 }}
                </StatBtn>
              </div>
              <div class="temp">
                <StatBtn variable="t7" driver="piec" type="list">
                  {{ piec.t7 }}
                </StatBtn>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-7">
        <h5 class="mt-3 mt-md-4 pt-3 text-center text-md-left">
          Sposób ogrzewania
        </h5>
        <div
          class="
            d-flex
            justify-content-center justify-content-md-start
            flex-wrap
          "
        >
          <FunctionBtn
            driver="piec"
            func="dmode"
            :autoload="true"
            size="sm"
            class="my-3"
          >
            <template #append> </template>
          </FunctionBtn>
        </div>

        <h5 class="mt-3 text-center text-md-left">Statystyki</h5>
        <div
          class="
            my-2
            d-flex
            justify-content-center justify-content-md-start
            flex-wrap
          "
        >
          <StatBtn
            variable="grz_b1"
            data-param="count"
            driver="piec"
            class="badge badge-primary badge-xl mr-2 mb-2"
          >
            Stan grzałki 1:
            {{ piec.grz_b1 }}
          </StatBtn>

          <StatBtn
            variable="grz_b2"
            data-param="count"
            driver="piec"
            class="badge badge-primary badge-xl mr-2 mb-2"
          >
            Stan grzałki 2:
            <strong>{{ piec.grz_b2 }}</strong>
          </StatBtn>

          <StatBtn
            variable="grz_b3"
            data-param="count"
            driver="piec"
            class="badge badge-primary badge-xl mr-2 mb-2"
          >
            Stan grzałki 3:
            <strong>{{ piec.grz_b3 }}</strong>
          </StatBtn>

          <StatBtn
            variable="pomp_ciepla"
            data-param="count"
            driver="piec"
            class="badge badge-primary badge-xl mr-2 mb-2"
          >
            Pompa ciepła:
            <strong>{{ piec.pomp_ciepla }}</strong>
          </StatBtn>
        </div>

        <hr />
        <div class="mt-3 text-center text-md-left">
          Użycie podłogówki:
          <StatBtn
            variable="floorUsage"
            driver="piec"
            type="list"
            class="badge badge-light badge-xl"
          >
            <strong> {{ piec.floorUsage }}% </strong>
          </StatBtn>
        </div>

        <div class="mt-3 text-center text-md-left">
          Temp powrotu pompy ciepła:
          <StatBtn
            variable="t2"
            driver="piec"
            type="list"
            class="badge badge-light badge-xl"
          >
            <strong> {{ piec.t2 }}</strong>
          </StatBtn>
        </div>

        <hr />

        <div
          class="mt-3 text-center text-md-left d-flex justify-content-between align-items-center"
        >
          <div>Inv PC; Garaż:</div>
          <FunctionBtn
            driver="piec"
            func="inverter"
            :autoload="true"
            align="right"
            size="sm"
          />
        </div>

        <div
          class="mt-3 text-center text-md-left d-flex justify-content-between align-items-center"
        >
          <div>Max temp PC:</div>
          <FunctionBtn
            driver="piec"
            func="setmaxpctemp"
            :autoload="true"
            align="right"
            size="sm"
          />
        </div>

        <div
          class="mt-3 text-center text-md-left d-flex justify-content-between align-items-center"
        >
          <div>Max temp PC (2 taryfa):</div>
          <FunctionBtn
            driver="piec"
            func="setmaxpctnoc"
            :autoload="true"
            align="right"
            size="sm"
          />
        </div>
        
      </div>
        
        
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState } from "vuex";
import StatBtn from "../components/helpers/stat-btn.vue";

export default {
  name: "FurnaceView",

  components: {
    StatBtn,
  },

  computed: {
    ...mapState(["driversData"]),
    piec() {
      if ("piec" in this.driversData) {
        return this.driversData.piec;
      } else {
        return false;
      }
    },

    energy() {
      if ("piec" in this.driversData) {
        const avg =
          (this.driversData.piec.t4 +
            this.driversData.piec.t5 +
            this.driversData.piec.t6 +
            this.driversData.piec.t4) /
          4;

        if (avg < 40) {
          return "fa-battery-empty";
        } else if (avg < 50) {
          return "fa-battery-quarter";
        } else if (avg < 60) {
          return "fa-battery-half";
        } else if (avg < 70) {
          return "fa-battery-three-quarters";
        } else if (avg < 100) {
          return "fa-battery-full";
        }
      }

      return "fa-battery-empty";
    },
  },
};
</script>

<style lang="scss" scoped>
.bufor {
  margin: 50px 50px 50px 0;
  padding: 0;
  display: inline-block;
  position: relative;
  text-align: center;
  i {
    display: inline-block;
    transform: rotate(-90deg);
    font-size: 10em;
  }
  .b-temp {
    position: absolute;
    right: 0;
    top: 0;
    margin: 10px 0;
    .temp {
      padding: 3px 12px;
      background: rgb(250, 32, 61);
      border-radius: 25px;
      margin-bottom: 11px;
      font-size: 0.9em;
      text-align: center;
      a {
        color: #fff;
      }
    }
  }
}
</style>
